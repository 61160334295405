import React from 'react';
import { getCurrentCompanyName } from '../../helper/PackageJsonHelper';
import { getStyle } from '../../styles/styles';
var style = getStyle();
export default class Footer extends React.Component {
    state = {

    }
    render() {
        return (
            <footer>
                {getCurrentCompanyName() ==='Newport'&&
                <div className="footer-p" >
                    <div className="row">
                        <div className="col-md-12">
                            <p>
                                The UK Financial Conduct Authority（FCA） and Prudential Regulatory Authority（PRA）are the regulators of the financial industry in the United Kingdom. As one of the most respected and sound financial service regulators in the World, the UK financial regulatory authorities are known for their high access threshold in financial market, strict compliance with regulations and strict penalties for financial institutions violating laws.
                            
                                AFEX (UK) Limited has been authorised by the Financial Conduct Authority (FCA) to carry operations in the UK and EEA, with a certificate issued by the UK HMRC. We are proud to say our company is one of the first European institutions to be granted with international payment service permission in Britain and Europe.
                            
                                The company has kept within strict compliance with the requirements of the law and regulators since its establishment. It has an excellent record of supervision with the UK regulatory bodies and works with them to report any suspicions of unlawful activity that enter into the networks.</p>
                        </div>
                    </div>
                </div>
                }
                <div className="container" style={style.footerColor} >
                    <div className="col-md-12">
                        <div className="copyright uk-text-center navbar-center">
                        {/* {getCurrentCompanyName() === 'Xushi' && `ABN: 22 151 337 852 | AFSL: 405948`}<br /> */}
                        {/* {`Build Date: ${preval`module.exports = new Date().toLocaleString();`}, Version: ${getCurrentVersionNumber()}`}<br/> */}
                        </div>
                    </div>
                </div>
            </footer >
        );
    }
}